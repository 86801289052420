import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <video width="750" height="500" controls autoplay>
        <source src="./Videos/video.mp4" type="video/mp4"/>
      </video>
        <p>
          <a className="App-link" href="https://www.youtube.com/watch?v=uLB-4P_lJr4">Original YouTube Video</a>
        </p>
      </header>
    </div>
  );
}

export default App;
